var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-layout"},[_c('a-layout',{attrs:{"id":"components-layout-demo-custom-trigger"}},[_c('a-layout-sider',{attrs:{"trigger":null,"collapsible":"","width":240},model:{value:(_vm.collapsed),callback:function ($$v) {_vm.collapsed=$$v},expression:"collapsed"}},[_c('div',{staticClass:"logo"},[_c('img',{staticStyle:{"width":"72px","transform":"scale(2, 2.2) translateY(6px)"},attrs:{"src":_vm.Logo}}),_c('span',{staticClass:"app-name",attrs:{"title":_vm.appTitle}},[_vm._v(_vm._s(_vm.appTitle))])]),_c('sidebar',{attrs:{"collapsed":_vm.collapsed}})],1),_c('a-layout',{staticClass:"layout-content"},[_c('a-layout-header',{staticStyle:{"background":"#fff","padding":"0"}},[_c('a-icon',{staticClass:"trigger",attrs:{"type":_vm.collapsed ? 'menu-unfold' : 'menu-fold'},on:{"click":() => (_vm.collapsed = !_vm.collapsed)}}),_c('breadcrumbs'),_c('div',{staticClass:"header-right"},[_c('div',{staticClass:"content"},[_c('span',{staticClass:"username"},[_vm._v(_vm._s(_vm.username))]),_c('a-dropdown',{attrs:{"placement":"bottomCenter"}},[_c('a-avatar',{staticStyle:{"cursor":"pointer"},attrs:{"src":_vm.avatar,"size":40}}),_c('a-menu',{attrs:{"slot":"overlay"},on:{"click":_vm.handleUserMenuClick},slot:"overlay"},[_c('a-menu-item',{key:"logout"},[_c('a',[_vm._v("Log out")])])],1)],1)],1)])],1),_c('a-layout-content',{style:({
          margin: '24px 16px',
          padding: '24px',
          minHeight: '280px',
          backgroundColor: '#fff',
          borderTop: '1px solid #e9e9e9'
        })},[_c('router-view')],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }